<template>
  <div>
    <template v-if="loaded">
      <div class="intro-product">
        <div class="texts-product">
          <h3 class="name-category markazi d-inline-block white--text">
            {{ categorySelectedToShow[0].name }}
          </h3>

          <h1 class="name-product">{{ product.name }}</h1>
        </div>

        <v-img
          v-if="product.cover !== null"
          v-bind:src="
            'https://www.lakewaycms.actstudio.xyz/storage/uploads' +
            product.cover.path
          "
          eager
          class="intro-product--background"
        >
        </v-img>

        <v-img
          v-if="product.intro_miniature !== null"
          v-bind:src="
            'https://www.lakewaycms.actstudio.xyz' +
            product.intro_miniature.path
          "
          eager
          contain
          class="img-miniature"
        ></v-img>
      </div>

      <v-container fluid class="second-block-move">
        <v-row class="second-block-content" justify="center" align-md="center">
          <v-col
            cols="12"
            md="auto"
            class="max-width-column-carousel pt-0 pt-sm-3"
          >
            <template v-if="$vuetify.breakpoint.smAndDown">
              <div class="text-center block-texts-presentations">
                <h3 class="title-block-2 pinyon color-green-2">Farm fresh</h3>
                <span
                  class="title-block-2-second-line color-green-2 bold roboto"
                  >is always best</span
                >
              </div>
            </template>

            <template v-if="loadCarousel">
              <hooper
                
                v-bind:settings="hooperSettings"
                v-bind:trimWhiteSpace="true"
                @slide="updateCarousel"
                ref="carousel"
              >
                <slide
                  v-for="(presentation, pk) in product.presentationsArray"
                  v-bind:key="'presentations-' + pk"
                  v-show="presentation.value.image !== null"
                >
                  <div>
                    <img
                      :src="
                        'https://www.lakewaycms.actstudio.xyz' +
                        presentation.value.image.path
                      "
                      class="d-inline-block pointer presentation-img"
                    />
                  </div>
                </slide>
                <hooper-pagination slot="hooper-addons"></hooper-pagination>
              </hooper>
            </template>
          </v-col>
          <v-col
            cols="12"
            md="auto"
            class="px-10 px-sm-3 presentations-texts-carousel"
          >
            <div class="text-center">
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <div class="text-farm">
                  <h3 class="title-block-2 pinyon color-green-2">
                    Farm fresh <br />
                    <span
                      class="
                        title-block-2-second-line
                        color-green-2
                        bold
                        roboto
                      "
                      >is always best</span
                    >
                  </h3>
                </div>
              </template>

              <ul class="pl-0 presentations-list" v-if="product.presentationsArray.name !== ''">
                <li
                  v-for="(presentation, pre) in product.presentationsArray"
                  v-bind:key="pre"
                  class="item-presentation"
                  @click="carouselPresentations = pre"
                  style="cursor: pointer"
                >
                  <span
                    v-bind:style="
                      carouselPresentations === pre ? 'opacity: .3' : ''
                    "
                    >{{ presentation.value.name }}</span
                  >
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid class="mt-0 pt-0">
        <v-row class="third-block-product">
          <v-col>
            <v-row
              no-gutters
              align-sm="center"
              class="fill-height"
              justify="center"
            >
              <v-col cols="12" md="auto">
                <template v-if="product.benefitsImage !== null">
                  <v-img
                    :src="
                      'https://www.lakewaycms.actstudio.xyz' +
                      product.benefitsImage.path
                    "
                    eager
                    class="img-benefits-new"
                  >
                  </v-img>
                </template>
              </v-col>
              <v-col
                cols="12"
                md="auto"
                class="column-2-benefits text-center text-sm-left"
              >
                <h3
                  class="
                    title-block-2
                    pinyon
                    color-green-2
                    text-center
                    line-height-custom-1651
                  "
                >
                  Do you know <br />
                  <span
                    class="
                      title-block-2-second-line
                      color-green-2
                      bold
                      max-width-title-benefits
                      roboto
                    "
                    >the healthy benefits <br />
                    of
                    <span
                      class="d-inline-block roboto color-green-2 text-inside"
                    >{{ categorySelectedToShow[0].name }}</span>?</span
                  >
                </h3>

                <ul class="benefits-list">
                  <li
                    v-for="(benefit, b) in product.benefitsArray"
                    v-bind:key="b"
                  >
                    <span style="color: #115224">
                      {{ benefit.value }}
                    </span>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="banner-green">
          <v-col class="pa-0">
            <v-row no-gutters>
              <v-col cols="12" md="6" order="1" order-md="0">
                <v-row
                  no-gutters
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <v-col class="title-banner-green-div" cols="auto">
                    <h3 class="uppercase white--text title-banner-green">
                      {{ productSingleton.text }}
                    </h3>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6" order="0" order-md="1">
                <template v-if="productSingleton.image !== null">
                  <v-img
                    v-bind:src="
                      'https://www.lakewaycms.actstudio.xyz' +
                      productSingleton.image.path
                    "
                    eager
                    class="img-banner-green"
                  ></v-img>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="related-products" :fluid="$vuetify.breakpoint.width > 2299 ? true : false">
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <h3 class="title-related-products">You might also like...</h3>
          </v-col>

          <router-link
            :to="route1"
            class="black--text not-text-decoration"
          >
            <v-col
              cols="12"
              md="auto"
              v-if="
                product.related_product1 && product.related_product1.url !== ''
              "
              class="item-related"
            >
              <div class="background-avatar">
                <v-row
                  no-gutters
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <v-col cols="auto">
                    <v-img
                      v-if="product.related_product1.intro_miniature !== null"
                      v-bind:src="
                        'https://www.lakewaycms.actstudio.xyz' +
                        product.related_product1.intro_miniature.path
                      "
                      eager
                      class="background-avatar--img"
                    ></v-img>
                  </v-col>
                </v-row>
              </div>
              <div class="text-center">
                <h3 class="name-related-product">
                  {{ product.related_product1.name }}
                </h3>
              </div>
            </v-col>
          </router-link>

          <router-link
            :to="route2"
            class="black--text not-text-decoration"
          >
            <v-col
              cols="12"
              md="auto"
              v-if="
                product.related_product2 && product.related_product2.url !== ''
              "
              class="related-product-column item-related"
            >
              <div class="background-avatar">
                <v-row
                  no-gutters
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <v-col cols="auto">
                    <v-img
                      v-if="product.related_product2.intro_miniature !== null"
                      v-bind:src="
                        'https://www.lakewaycms.actstudio.xyz' +
                        product.related_product2.intro_miniature.path
                      "
                      eager
                      class="background-avatar--img"
                    ></v-img>
                  </v-col>
                </v-row>
              </div>
              <div class="text-center">
                <h3 class="name-related-product">
                  {{ product.related_product2.name }}
                </h3>
              </div>
            </v-col>
          </router-link>

          <router-link
            :to="route3"
            class="black--text not-text-decoration"
          >
            <v-col
              cols="12"
              md="auto"
              v-if="
                product.related_product3 && product.related_product3.url !== ''
              "
              class="related-product-column item-related"
            >
              <div class="background-avatar">
                <v-row
                  no-gutters
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <v-col cols="auto">
                    <v-img
                      v-if="product.related_product3.intro_miniature !== null"
                      v-bind:src="
                        'https://www.lakewaycms.actstudio.xyz' +
                        product.related_product3.intro_miniature.path
                      "
                      eager
                      class="background-avatar--img"
                    ></v-img>
                  </v-col>
                </v-row>
              </div>
              <div class="text-center">
                <h3 class="name-related-product">
                  {{ product.related_product3.name }}
                </h3>
              </div>
            </v-col>
          </router-link>
        </v-row>
      </v-container>
    </template>

    <!-- moidal -->
    <!-- <template v-if="modal">
      <v-dialog v-model="modal" max-width="900" id="product-modal">
        <v-carousel v-model="presentationSelected" hide-delimiters height="auto" dark @change="
          findInCarouselBig(product.presentationsArray[3].value.image.path)
        ">
          <v-carousel-item v-for="(press, pk) in product.presentationsArray" v-bind:key="'carousel-' + pk">
            <v-card light width="900" class="position-relative card-carousel-modal" elevation="0" flat>
              <v-btn absolute top right class="px-0 close-btn" elevation="0" :riple="false" text @click="modal = false">
                <v-icon class="icon-close">mdi-close</v-icon>
              </v-btn>
              <v-card-text class="px-7 py-12 px-md-12">
                <v-container fluid>
                  <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" class="text-center">
                      <v-img :src="
                        'https://www.lakewaycms.actstudio.xyz' +
                        press.value.image.path
                      " max-height="447" contain eager></v-img>
                      <h3 class="black--text presentation-text">
                        {{ press.value.name }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
    </template> -->
  </div>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "Product",
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  computed: {
    allImages: function () {
      var presentations = this.product.presentationsArray;
      var gallery = this.product.gallery;
      var arrayAllImages = [];
      // arrayAllImages.push({ path: this.product.cover.path });
      for (let i = 0; i < gallery.length; i++) {
        const element = gallery[i];
        arrayAllImages.push({ path: element.path });
      }

      for (let i = 0; i < presentations.length; i++) {
        const element = presentations[i];
        arrayAllImages.push({ path: element.value.image.path });
      }

      return arrayAllImages;
    },
    heightCarouselMobile: function () {
      var height = "100vw";

      if (this.$vuetify.breakpoint.xs) {
        height = "100vw";
      } else {
        height = "40vh";
      }

      return height;
    },
    heightCarouselBig: function () {
      var height = "calc(65vh - 50px)";

      if (this.$vuetify.breakpoint.xl) {
        height = "calc(65vh - 50px)";
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        height = "calc(64vh - 50px)";
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        height = "calc(68vh - 50px)";
      } else {
        height = "35vh";
      }

      return height;
    },
    categoriesF: function () {
      var categorySelected = [];
      var categories = this.categoriesP;

      for (let i = 0; i < categories.length; i++) {
        const element = categories[i];

        if (element.products !== null) {
          for (let j = 0; j < element.products.length; j++) {
            const element2 = element.products[j];

            if (element2.name === this.product.name) {
              categorySelected.push(element);
            } else {
              console.log("no lo encontró");
            }
          }
        }
      }

      for (let i = 0; i < categories.length; i++) {
        const element = categories[i];

        if (element.subcategories !== null) {
          for (let j = 0; j < element.subcategories.length; j++) {
            const element2 = element.subcategories[j];

            for (let x = 0; x < element2.products.length; x++) {
              const element3 = element2.products[x];

              if (element3.name === this.product.name) {
                categorySelected.push(element);
              } else {
                console.log("no lo encontró 2");
              }
            }
          }
        }
      }
      return categorySelected;
    },
    productsF: function () {
      var categoriesF = this.categoriesP;
      var arrayN = [];

      for (let i = 0; i < categoriesF.length; i++) {
        const element = categoriesF[i];
        let auxCategory = {
          name: element.name,
          url: element.url,
          elements: [],
        };
        let n = 0;
        if (element.products !== null) {
          for (let x = 0; x < element.products.length; x++) {
            const element3 = element.products[x];
            let auxProduct = {
              name: element3.name,
              type: "product",
              path: element3.intro_miniature.path,
              url: element3.url,
              index: n,
            };
            n++;
            auxCategory.elements.push(auxProduct);
          }
        }

        if (element.subcategories !== null) {
          for (let j = 0; j < element.subcategories.length; j++) {
            const element2 = element.subcategories[j];
            let auxSubcategory = {
              name: element2.name,
              type: "subcategoryF",
              path: null,
              index: null,
            };
            auxCategory.elements.push(auxSubcategory);
            if (element2.products !== null) {
              for (let x = 0; x < element2.products.length; x++) {
                const element3 = element2.products[x];
                let auxProduct = {
                  name: element3.name,
                  type: "product",
                  path: element3.intro_miniature.path,
                  url: element3.url,
                  index: n,
                };
                n++;
                auxCategory.elements.push(auxProduct);
              }
            }
          }
        }

        arrayN.push(auxCategory);
      }
      
      return arrayN;
    },
    categorySelectedToShow: function () {
      var categorySelected = {};
       
      categorySelected = this.productsF.filter((element)=>element.url === this.$route.params.category);
      return categorySelected;
    }
  },
  data() {
    return {
      productSingleton: {
        image: {
          path: "",
        },
        text: "",
      },
      product: {
        benefitsArray: [],
        image: {
          path: "",
        },
        presentationsArray: [],
        cover: {
          path: "",
        },
        intro_miniature: {
          path: "",
        },
        benefitsImage: {
          path: "",
        },
      },
      categoriesP: [],
      subcategoriesP: [],
      loaded: false,
      auxPresentation: null,
      modal: false,
      presentationSelected: 0,
      carouselBig: 0,
      carouselBenefits: 0,
      carouselPresentations: 0,
      hooperSettings: {
        itemsToShow: 1,
        infiniteScroll: true,
        mouseDrag: false,
        wheelControl: false,
        centerMode: true,
        transition: 0,
      },
      srcP: "",
      loadCarousel: false,
      route1: '',
      route2: '',
      route3: ''
    };
  },
  watch: {
    $route() {
      this.loaded = false;
      this.loadCarousel = false;

      let key = "";

      const opts =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

      for (let index = 0; index < 10; index++) {
        key = key + opts.charAt(Math.random() * 61);
      }

      fetch(
        "https://www.lakewaycms.actstudio.xyz/api/collections/get/Products?q=" +
          key,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            filter: { url: this.$route.params.url },
            populate: 1, // resolve linked collection items
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => (this.product = res.entries[0]))
        .then((this.loaded = true));
      setTimeout(() => {
        this.loadCarousel = true;
      }, 1000);
      setTimeout(()=>{
        this.findRelatedProduct();
      }, 1000);
    },
    modal: function () {
      if (!this.modal) {
        this.auxPresentation = null;
      }
    },
    carouselPresentations() {
      this.$refs.carousel.slideTo(this.carouselPresentations);
    },
  },
  async beforeCreate() {
    let key = "";

    const opts =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijlkmnopqrstuvwxyz1234567890";

    for (let index = 0; index < 10; index++) {
      key = key + opts.charAt(Math.random() * 61);
    }

    var res = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/collections/get/Products?q=" +
        key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: { url: this.$route.params.url },
          populate: 1, // resolve linked collection items
        }),
      }
    ).then((res) => res.json());
    this.product = res.entries[0];

    var res2 = await fetch(
      "https://www.lakewaycms.actstudio.xyz/api/singletons/get/product?q=" +
        key,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 2, // resolve linked collection items
        }),
      }
    ).then((res) => res.json());
    this.productSingleton = res2.content;

    this.loaded = true;
    this.loadCarousel = true;
  },
  beforeMount: function () {
    fetch(
      "https://www.lakewaycms.actstudio.xyz/api/collections/get/categoriesProducts",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sort: { _o: 1 },
          populate: 3,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.categoriesP = res.entries))
      .catch((error) => console.log(error.message));

    fetch(
      "https://www.lakewaycms.actstudio.xyz/api/collections/get/subcategoriesProducts",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 1,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.subcategoriesP = res.entries))
      .catch((error) => console.log(error.message));
  },
  mounted () {
    setTimeout(()=>{
      this.findRelatedProduct();
    }, 3000);
  },
  methods: {
    findInCarouselBig: function (src) {
      this.carouselBig = this.allImages.findIndex((data) => data.path === src);
      this.srcP = src;
    },
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    updateCarousel(payload) {
      this.carouselPresentations = payload.currentSlide;
    },
    findRelatedProduct: function () {
        for (let i = 0; i < this.productsF.length; i++) {
          const element = this.productsF[i];

          for (let j = 0; j < element.elements.length; j++) {
            const element2 = element.elements[j];
            if(element2.type === 'product'){

              if(element2.url === this.product.related_product1.url) {
                // console.log(element);
                this.route1 = '/product/' + element.url + '/' + element2.url;
              }
              if(element2.url === this.product.related_product2.url) {
                // console.log(element);
                this.route2 = '/product/' + element.url + '/' + element2.url;

              }
              if(element2.url === this.product.related_product3.url) {
                // console.log(element);
                this.route3 = '/product/' + element.url + '/' + element2.url;
              }
            }
          }
        }
    }
  },
};
</script>

<style lang="scss" scoped>
$xxl: 2300px;
$xl: 1903px;
$lg: 1263px;
$md: 959px;
$sm: 599px;

.related-product {
  line-height: 1.1;

  &:hover {
    opacity: 0.5;
  }
}

.div-intro-product {
  padding: 0px 144px;

  @media screen and(max-width: $xl) {
    padding: 0px 60px;
  }

  @media screen and(max-width: $md) {
    padding: 0px 90px;
  }

  @media screen and(max-width: $sm) {
    padding: 0px 29px;
  }
}

// .description-product {
//   // margin: 20px 0px;
// }

.presentations-title {
  padding-left: 73px;
  padding-top: 8px;

  @media screen and(max-width: $xl) {
    padding-left: 56px;
    padding-top: 3px;
  }

  @media screen and(max-width: $sm) {
    padding-left: 6px;
  }
}

.presentation-img {
  // width: 135px;
  // width: 100%;

  // &.active-presentation {
  //   opacity: 0.8;
  // }
  width: 1000px;
  display: block !important;
  margin: auto;

  @media screen and(max-width: $xxl) {
    width: 700px;
  }
  @media screen and(max-width: $xl) {
    width: 550px;
  }

  @media screen and(max-width: $md) {
    width: 530px;
    display: block !important;
    margin: auto;
    object-fit: cover;
    height: 315px;
  }

  @media screen and(max-width: $sm) {
    width: 390px;
    height: auto;
  }
}

.text-presentation {
  display: block;
  max-width: 120px;
  line-height: 1.1;
  margin-top: 5px;

  @media screen and(max-width: $xl) {
  }

  @media screen and(max-width: $sm) {
    line-height: 1.2;
  }
}

.presentation-item {
  margin-right: 30px;
  margin-bottom: 30px;
}

.title-benefits {
  padding-bottom: 20px;
  padding-top: 30px;

  @media screen and(max-width: $xl) {
    padding-top: 25px;
  }

  @media screen and(max-width: $sm) {
    padding-top: 19px;
  }
}

.square-product {
  height: calc(100vh - 100px);
  padding: 64px 0px 48px 0px;

  @media screen and(max-width: $xl) {
    padding: 38px 0px 38px 0px;
  }

  @media screen and(max-width: $md) {
    height: auto;
    padding: 57px 0px 57px 0px;
  }

  @media screen and(max-width: $sm) {
    padding: 42px 0px 48px 0px;
  }
}

.presentation-text {
  margin-top: 15px;
}

.close-btn {
  top: 33px !important;
  right: 22px !important;
}

.icon-close {
  font-size: 30px !important;
}

.line-height-custom-16351 {
  @media screen and(max-width: $sm) {
    line-height: 1.6;
  }
}

.title-related-products {
  margin-top: 35px;
  margin-bottom: 15px;

  @media screen and(max-width: $sm) {
  }
}

.card-carousel-modal {
  height: 600px;

  @media screen and(max-width: $sm) {
    height: 400px;
  }
}

.dots {
  min-height: 29px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: center;

  .dot {
    width: 10px;
    height: 10px;
    background: #d6d6d6;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;

    &:last-child {
      margin-right: 0px;
    }

    &.active-item {
      background: #869791;
    }
  }
}

.div-header-product {
  // padding-bottom: 15px;
  border-bottom: 2px solid #d6cfbe;
  width: fit-content;
}

.carousel-presentations {
  height: calc(35vh - 50px);
  background: #105224;
  padding: 25px 35px;

  @media screen and(max-width: $xl) {
    height: calc(36vh - 50px);
    padding: 20px 35px;
  }

  @media screen and(max-width: $lg) {
    height: calc(32vh - 50px);
  }

  @media screen and(max-width: $md) {
    height: 24vh;
  }

  @media screen and(max-width: $sm) {
    height: auto;
    padding: 30px 35px;
  }
}

.next,
.prev {
  position: absolute;
  top: 42%;
  transform: translateY(-50%);
  z-index: 5;
  width: 36px;
  height: 36px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  cursor: pointer;
  text-align: center;

  @media screen and(max-width: $xl) {
    height: 30px !important;
    width: 30px !important;
  }

  @media screen and(max-width: $sm) {
    height: 24px !important;
    width: 24px !important;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  // @media screen and(max-width: $sm) {
  //   display: none !important;
  // }
}

.prev {
  left: 0px;

  @media screen and(max-width: $md) {
    left: 0px;
  }

  @media screen and(max-width: $sm) {
    left: -27px;
  }
}

.next {
  right: 0px;

  @media screen and(max-width: $md) {
    right: 0px;
  }

  @media screen and(max-width: $sm) {
    right: -27px;
  }
}

.carousel-row {
  padding: 0 60px;
  margin-top: 8px;

  @media screen and(max-width: $md) {
    margin-top: 18px;
  }

  @media screen and(max-width: $sm) {
    padding: 0;
  }
}

.name-category {
  background: #0c762d;
  border-radius: 58px;
  font-size: 56px;
  padding: 10px 80px;
  
  @media screen and(max-width: $xxl) {
    padding: 2px 60px;
    font-size: 42px;
  }

  @media screen and(max-width: $xl) {
    font-size: 30px;
    padding: 2px 43px;
  }
}

.product-name {
  font-size: 26px;

  @media screen and(max-width: $xl) {
    font-size: 20px;
  }
}

.product-subcategory {
  font-size: 22px;

  @media screen and(max-width: $xl) {
    font-size: 16px;
  }
}

.title-benefits-o {
  padding-bottom: 20px;
  padding-top: 20px;
}

.product-titles {
  padding: 0 150px;
  margin-top: 40px;

  @media screen and(max-width: $md) {
    padding: 0 90px;
  }

  @media screen and(max-width: $sm) {
    padding: 0 30px;
    margin-top: 23px;
  }
}

.text-presentation {
  line-height: 1.1;
}

.intro-product {
  height: calc(100vh - 100px);
  position: relative;
  @media screen and(max-width: $xl) {
    height: calc(100vh - 80px);
  }

  @media screen and(max-width: $md) {
    height: calc(100vh - 400px);
  }

  @media screen and(max-width: $sm) {
    height: calc(100vh - 80px);
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    background: url(../assets/img/WAVE-PRODUCTS.png);
    width: 100%;
    height: 100%;
    background-position-y: 53%;
    z-index: 5;
    background-size: contain;
    background-repeat: no-repeat;
    @media screen and(max-width: $xl) {
      background-position-y: 59%;
    }

    @media screen and(max-width: $lg) {
      background-position-y: 64%;
    }
    @media screen and(max-width: $md) {
      background-position-y: 50%;
    }
    @media screen and(max-width: $sm) {
      background-position-y: 56% !important;
      background: url(../assets/img/curva-verde-mobile.png);
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

  &--background {
    height: 56%;
    position: relative;
    @media screen and(max-width: $xl) {
      height: 59%;
    }

    @media screen and(max-width: $lg) {
      height: 60%;
    }

    @media screen and(max-width: $md) {
      height: 52%;
    }

    @media screen and(max-width: $sm) {
      height: 57%;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(27, 72, 60, 0.4);
      pointer-events: none;
    }
  }

  .img-miniature {
    position: absolute;
    // bottom: -45px;
    z-index: 5;
    right: 150px;
    max-width: 100%;
    top: 39%;
    width: 800px;

    @media screen and(max-width: $xxl) {
      width: 577px;
    }
    @media screen and(max-width: $xl) {
      width: 370px;
      right: 90px;
    }
    @media screen and(max-width: $lg) {
      width: 400px;
      right: 90px;
      top: 49%;
    }
    @media screen and(max-width: $md) {
      top: 41%;
      width: 420px;
      right: 26px;
    }
    @media screen and(max-width: $sm) and(min-height: 0px){
      right: 0px;
      width: 320px;
      top: 47%;
    }

    @media screen and(max-width: $sm) and(min-height: 740px){
      right: 0px;
      width: 320px;
      top: 50%;
    }

    @media screen and(max-width: $sm) and(min-height: 1030px){
      right: 0px;
      width: 320px;
      top: 54%;
    }
  }

  .texts-product {
    position: absolute;
    z-index: 5;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    top: 99px;
    @media screen and(max-width: $xxl) {
      top: 83px;
    }
    @media screen and(max-width: $xl) {
      top: 60px;
    }
    @media screen and(max-width: $md) {
      top: 96px;
    }

    @media screen and(max-width: $sm) {
      top: 60px;
      width: 90%;
    }
  }

  .name-product {
    color: white;
    line-height: 1;
    font-size: 110px;
    margin-top: 72px;
    
    @media screen and(max-width: $xxl) {
      margin-top: 31px;
      font-size: 78px;
    }
    @media screen and(max-width: $xl) {
      margin-top: 30px;
      font-size: 48px;
    }
    @media screen and(max-width: $sm) {
      font-size: 48px;
    }
  }
}
.max-width-column-carousel {
  max-width: 952px;
  @media screen and(max-width: $xxl) {
    max-width: 752px;
  }
  @media screen and(max-width: $xl) {
    max-width: 550px;
    transform: translateX(-100px);
  }
  @media screen and(max-width: $md) {
    transform: translateX(0px);
  }
}

.title-block-2 {
  line-height: 0.8;
  font-size: 70px;

  @media screen and(max-width: $xxl) {
    font-size: 52px;
  }
  @media screen and(max-width: $xl) {
    font-size: 36px;
  }
  @media screen and(max-width: $md) {
    line-height: 1.2;
    font-size: 38px;
  }
  @media screen and(max-width: $sm) {
  }
}

.title-block-2-second-line {
  font-size: 62px;
  @media screen and(max-width: $xxl) {
    font-size: 42px;
  }
  @media screen and(max-width: $xl) {
    font-size: 26px;
  }
  @media screen and(max-width: $md) {
    font-size: 33px;
  }

  > span {
    font-size: 44px;
    @media screen and(max-width: $xl) {
      font-size: 34px;
    }
    @media screen and(max-width: $md) {
      font-size: 33px;
    }
  }
}

.third-block-product {
  background: #fbfaf6;
  min-height: 984px;
  @media screen and(max-width: $xxl) {
    min-height: 850px;
  }
  @media screen and(max-width: $xl) {
    min-height: 550px;
    padding: 65px 0;
  }
  @media screen and(max-width: $md) {
    padding: 65px;
    padding-top: 30px;
  }
  @media screen and(max-width: $sm) {
    min-height: auto;
    padding: 90px 0px;
  }
}
.presentations-list {
  list-style: none;
  margin-top: 60px;
  @media screen and(max-width: $xxl) {
    margin-top: 30px;
  }
  @media screen and(max-width: $xl) {
    margin-top: 15px;
  }
  @media screen and(max-width: $md) {
    max-width: 399px;
    margin: auto;
    margin-top: 80px;
  }
  @media screen and(max-width: $sm) {
    max-width: 100%;
    // margin-bottom: 100px;
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .item-presentation {
    display: block;
    border-bottom: 1px solid #115224;
    padding: 28px 60px;

    @media screen and(max-width: $xxl) {
      padding: 22px 60px;
    }

    @media screen and(max-width: $xl) {
      padding: 16px 40px;
    }

    @media screen and(max-width: $md) {
      padding: 20px 40px;
    }

    @media screen and(max-width: $sm) {
      padding: 16px 0px;
    }

    span {
      color: #115224;
      text-transform: uppercase;
      font-size: 38px;
      @media screen and(max-width: $xxl) {
        font-size: 30px;
      }

      @media screen and(max-width: $xl) {
        font-size: 18px;
      }
      @media screen and(max-width: $sm) {
        font-size: 18px;
      }
    }
  }
}
.img-benefits-new {
  max-width: 600px;
  @media screen and(max-width: $xxl) {
    max-width: 536px;
  }
  @media screen and(max-width: $xl) {
    max-width: 385px;
  }
  @media screen and(max-width: $md) {
    display: block;
    margin: auto;
  }
  @media screen and(max-width: $sm) {
    max-width: 216px;
  }
}

.column-2-benefits {
  margin-left: 252px;
  @media screen and(max-width: $xxl) {
    margin-left: 160px;
  }
  @media screen and(max-width: $xl) {
    margin-left: 100px;
  }
  @media screen and(max-width: $lg) {
    margin-left: 70px;
  }
  @media screen and(max-width: $md) {
    margin-left: 0px;
    // padding-bottom: 100px;
    // padding-top: 100px;
  }
}

.banner-green {
  background: #115224;
}
.title-banner-green {
  line-height: 1.1;
  font-size: 76px;
  @media screen and(max-width: $xxl) {
    font-size: 58px;
  }
  @media screen and(max-width: $xl) {
    font-size: 40px;
  }
  @media screen and(max-width: $sm) {
    font-size: 33px;
  }
}
.title-banner-green-div {
  max-width: 759px;

  @media screen and(max-width: $xxl) {
    max-width: 590px;
  }
  @media screen and(max-width: $xl) {
    max-width: 402px;
  }
  @media screen and(max-width: $lg) {
    max-width: 401px;
  }
  @media screen and(max-width: $md) {
    padding: 144px 0;
    max-width: 417px;
    text-align: center;
  }
  @media screen and(max-width: $sm) {
    max-width: 250px;
    padding: 80px 0;
  }
}
.img-banner-green {
  height: 850px;
  @media screen and(max-width: $xxl) {
    height: 550px;
  }
  @media screen and(max-width: $xl) {
    height: 400px;
  }

  @media screen and(max-width: $md) {
    height: 420px;
  }
  @media screen and(max-width: $sm) {
    height: 345px;
  }
}
.related-product-column {
  margin-left: 160px;
  @media screen and(max-width: $xl) {
    margin-left: 70px;
  }
  @media screen and(max-width: $lg) {
    margin-left: 21px;
  }
  @media screen and(max-width: $md) {
    margin-left: 0px;
  }
}

.item-related {
  position: relative;
  @media screen and(max-width: $lg) {
    margin-right: 20px;
  }

  @media screen and(max-width: $md) {
    margin-bottom: 50px;
  }
  @media screen and(max-width: $sm) {
    margin-bottom: 70px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  &:hover {
    .v-image {
      // transition: all .3s ease;
      transform: translateY(-40px);
      @media screen and(max-width: $xl) {
        transform: translateY(-20px);
      }
    }
  }
}
.background-avatar {
  
  border-radius: 50%;
  margin-bottom: 50px;
  background: rgba(241, 111, 92, 1);
  background: -moz-linear-gradient(
    top,
    rgba(241, 111, 92, 1) 0%,
    rgba(234, 231, 223, 1) 0%,
    rgba(234, 231, 223, 1) 51%,
    rgba(234, 231, 223, 1) 54%,
    rgba(234, 231, 223, 0.63) 71%,
    rgba(234, 231, 223, 0) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(241, 111, 92, 1)),
    color-stop(0%, rgba(234, 231, 223, 1)),
    color-stop(51%, rgba(234, 231, 223, 1)),
    color-stop(54%, rgba(234, 231, 223, 1)),
    color-stop(71%, rgba(234, 231, 223, 0.63)),
    color-stop(100%, rgba(234, 231, 223, 0))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(241, 111, 92, 1) 0%,
    rgba(234, 231, 223, 1) 0%,
    rgba(234, 231, 223, 1) 51%,
    rgba(234, 231, 223, 1) 54%,
    rgba(234, 231, 223, 0.63) 71%,
    rgba(234, 231, 223, 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(241, 111, 92, 1) 0%,
    rgba(234, 231, 223, 1) 0%,
    rgba(234, 231, 223, 1) 51%,
    rgba(234, 231, 223, 1) 54%,
    rgba(234, 231, 223, 0.63) 71%,
    rgba(234, 231, 223, 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(241, 111, 92, 1) 0%,
    rgba(234, 231, 223, 1) 0%,
    rgba(234, 231, 223, 1) 51%,
    rgba(234, 231, 223, 1) 54%,
    rgba(234, 231, 223, 0.63) 71%,
    rgba(234, 231, 223, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(241, 111, 92, 1) 0%,
    rgba(234, 231, 223, 1) 0%,
    rgba(234, 231, 223, 1) 51%,
    rgba(234, 231, 223, 1) 54%,
    rgba(234, 231, 223, 0.63) 71%,
    rgba(234, 231, 223, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f16f5c', endColorstr='#eae7df', GradientType=0 );
  width: 500px;
  height: 500px;

  @media screen and(max-width: $xxl) {
    width: 400px;
    height: 400px;
  }
  @media screen and(max-width: $xl) {
    width: 220px;
    height: 220px;
    margin-bottom: 30px;
  }

  @media screen and(max-width: $md) {
    display: block;
    margin: auto;
  }

  @media screen and(max-width: $sm) {
    width: 250px;
    height: 250px;
  }

  &--img {
    width: 420px;
    @media screen and(max-width: $xxl) {
      width: 320px;
    }
    @media screen and(max-width: $xl) {
      width: 180px;
    }
    @media screen and(max-width: $sm) {
      width: 200px;
    }
  }
}
.name-related-product {
  font-size: 46px;
  @media screen and(max-width: $xxl) {
    font-size: 34px;
  }
  @media screen and(max-width: $xl) {
    font-size: 18px;
  }
  @media screen and(max-width: $md) {
    margin-top: 41px;
  }
  @media screen and(max-width: $sm) {
    font-size: 24px;
    margin-top: 41px;
  }
}

.related-products {
  padding-top: 180px;
  padding-bottom: 180px;

  @media screen and(max-width: $xxl) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  @media screen and(max-width: $xl) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  @media screen and(max-width: $md) {
    padding-bottom: 33px;
  }

  @media screen and(max-width: $sm) {
    padding-top: 44px;
    padding-bottom: 44px;
  }
}
.title-related-products {
  font-size: 62px;
  margin-bottom: 91px;
  @media screen and(max-width: $xxl) {
    margin-bottom: 50px;
    font-size: 42px;
  }
  @media screen and(max-width: $xl) {
    font-size: 22px;
    margin-bottom: 30px;
  }
  @media screen and(max-width: $md) {
    margin-bottom: 70px;
  }
  @media screen and(max-width: $sm) {
    font-size: 33px;
    max-width: 219px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}

.benefits-list {
  margin-top: 70px;
  list-style: none;
  li {
    padding-left: 2rem;
    background-image: url(../assets/icons/background-dot.png);
    background-position: 0 14px;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    margin-bottom: 25px;
    @media screen and(max-width: $xl) {
      background-size: 6px;
    }

    @media screen and(max-width: $md) {
      background-image: none;
    }
  }
  @media screen and(max-width: $xl) {
    margin-top: 50px;
  }
  @media screen and(max-width: $md) {
    list-style: none;
    padding-left: 0px;
    margin-top: 71px;
    text-align: center;
  }

  li {
    margin-bottom: 20px;
    span {
      font-size: 38px;
      @media screen and(max-width: $xxl) {
        font-size: 30px;
      }
      @media screen and(max-width: $xl) {
        font-size: 18px;
      }
      @media screen and(max-width: $sm) {
        font-size: 18px;
      }
    }
  }
}
.presentations-texts-carousel {
  margin-left: 94px;
  @media screen and(max-width: $xxl) {
    margin-left: 12px;
  }
  @media screen and(max-width: $xl) {
    transform: translateX(-41px);
    margin-left: 0px;
  }
  @media screen and(max-width: $md) {
    transform: translateX(0px);
    transform: translateY(-50px);
  }
  @media screen and(max-width: $sm) {
    // margin-top: 20px;
    margin-top: 50px;
  }
}

.max-width-title-benefits {
  display: block;
  margin: auto;
  max-width: 100%;
  @media screen and(max-width: $sm) {
    max-width: 322px;
  }
}
.block-texts-presentations {
  margin-bottom: 30px;
  @media screen and(max-width: $md) {
    margin-bottom: 37px;
  }
}
.line-height-custom-1651 {
  line-height: 1.2;
}
.text-inside {
  font-size: 62px !important;
  @media screen and(max-width: $xxl) {
    font-size: 42px !important;
  }
  @media screen and(max-width: $xl) {
    font-size: 26px !important;
  }
  @media screen and(max-width: $md) {
    font-size: 33px !important;
  }
}

.second-block-move{
  margin-bottom: 160px;

  @media screen and(max-width: $xxl) {
    margin-bottom: 100px;
  }

}

.second-block-content{
  @media screen and(min-width: $xxl) {
    transform: translateY(-150px);
  }
}
</style>