var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loaded)?[_c('div',{staticClass:"intro-product"},[_c('div',{staticClass:"texts-product"},[_c('h3',{staticClass:"name-category markazi d-inline-block white--text"},[_vm._v(" "+_vm._s(_vm.categorySelectedToShow[0].name)+" ")]),_c('h1',{staticClass:"name-product"},[_vm._v(_vm._s(_vm.product.name))])]),(_vm.product.cover !== null)?_c('v-img',{staticClass:"intro-product--background",attrs:{"src":'https://www.lakewaycms.actstudio.xyz/storage/uploads' +
          _vm.product.cover.path,"eager":""}}):_vm._e(),(_vm.product.intro_miniature !== null)?_c('v-img',{staticClass:"img-miniature",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
          _vm.product.intro_miniature.path,"eager":"","contain":""}}):_vm._e()],1),_c('v-container',{staticClass:"second-block-move",attrs:{"fluid":""}},[_c('v-row',{staticClass:"second-block-content",attrs:{"justify":"center","align-md":"center"}},[_c('v-col',{staticClass:"max-width-column-carousel pt-0 pt-sm-3",attrs:{"cols":"12","md":"auto"}},[(_vm.$vuetify.breakpoint.smAndDown)?[_c('div',{staticClass:"text-center block-texts-presentations"},[_c('h3',{staticClass:"title-block-2 pinyon color-green-2"},[_vm._v("Farm fresh")]),_c('span',{staticClass:"title-block-2-second-line color-green-2 bold roboto"},[_vm._v("is always best")])])]:_vm._e(),(_vm.loadCarousel)?[_c('hooper',{ref:"carousel",attrs:{"settings":_vm.hooperSettings,"trimWhiteSpace":true},on:{"slide":_vm.updateCarousel}},[_vm._l((_vm.product.presentationsArray),function(presentation,pk){return _c('slide',{directives:[{name:"show",rawName:"v-show",value:(presentation.value.image !== null),expression:"presentation.value.image !== null"}],key:'presentations-' + pk},[_c('div',[_c('img',{staticClass:"d-inline-block pointer presentation-img",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
                      presentation.value.image.path}})])])}),_c('hooper-pagination',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2)]:_vm._e()],2),_c('v-col',{staticClass:"px-10 px-sm-3 presentations-texts-carousel",attrs:{"cols":"12","md":"auto"}},[_c('div',{staticClass:"text-center"},[(_vm.$vuetify.breakpoint.mdAndUp)?[_c('div',{staticClass:"text-farm"},[_c('h3',{staticClass:"title-block-2 pinyon color-green-2"},[_vm._v(" Farm fresh "),_c('br'),_c('span',{staticClass:"\n                      title-block-2-second-line\n                      color-green-2\n                      bold\n                      roboto\n                    "},[_vm._v("is always best")])])])]:_vm._e(),(_vm.product.presentationsArray.name !== '')?_c('ul',{staticClass:"pl-0 presentations-list"},_vm._l((_vm.product.presentationsArray),function(presentation,pre){return _c('li',{key:pre,staticClass:"item-presentation",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.carouselPresentations = pre}}},[_c('span',{style:(_vm.carouselPresentations === pre ? 'opacity: .3' : '')},[_vm._v(_vm._s(presentation.value.name))])])}),0):_vm._e()],2)])],1)],1),_c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"third-block-product"},[_c('v-col',[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align-sm":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[(_vm.product.benefitsImage !== null)?[_c('v-img',{staticClass:"img-benefits-new",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
                    _vm.product.benefitsImage.path,"eager":""}})]:_vm._e()],2),_c('v-col',{staticClass:"column-2-benefits text-center text-sm-left",attrs:{"cols":"12","md":"auto"}},[_c('h3',{staticClass:"\n                  title-block-2\n                  pinyon\n                  color-green-2\n                  text-center\n                  line-height-custom-1651\n                "},[_vm._v(" Do you know "),_c('br'),_c('span',{staticClass:"\n                    title-block-2-second-line\n                    color-green-2\n                    bold\n                    max-width-title-benefits\n                    roboto\n                  "},[_vm._v("the healthy benefits "),_c('br'),_vm._v(" of "),_c('span',{staticClass:"d-inline-block roboto color-green-2 text-inside"},[_vm._v(_vm._s(_vm.categorySelectedToShow[0].name))]),_vm._v("?")])]),_c('ul',{staticClass:"benefits-list"},_vm._l((_vm.product.benefitsArray),function(benefit,b){return _c('li',{key:b},[_c('span',{staticStyle:{"color":"#115224"}},[_vm._v(" "+_vm._s(benefit.value)+" ")])])}),0)])],1)],1)],1),_c('v-row',{staticClass:"banner-green"},[_c('v-col',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","order":"1","order-md":"0"}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{staticClass:"title-banner-green-div",attrs:{"cols":"auto"}},[_c('h3',{staticClass:"uppercase white--text title-banner-green"},[_vm._v(" "+_vm._s(_vm.productSingleton.text)+" ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","order":"0","order-md":"1"}},[(_vm.productSingleton.image !== null)?[_c('v-img',{staticClass:"img-banner-green",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
                    _vm.productSingleton.image.path,"eager":""}})]:_vm._e()],2)],1)],1)],1)],1),_c('v-container',{staticClass:"related-products",attrs:{"fluid":_vm.$vuetify.breakpoint.width > 2299 ? true : false}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h3',{staticClass:"title-related-products"},[_vm._v("You might also like...")])]),_c('router-link',{staticClass:"black--text not-text-decoration",attrs:{"to":_vm.route1}},[(
              _vm.product.related_product1 && _vm.product.related_product1.url !== ''
            )?_c('v-col',{staticClass:"item-related",attrs:{"cols":"12","md":"auto"}},[_c('div',{staticClass:"background-avatar"},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.product.related_product1.intro_miniature !== null)?_c('v-img',{staticClass:"background-avatar--img",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
                      _vm.product.related_product1.intro_miniature.path,"eager":""}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"name-related-product"},[_vm._v(" "+_vm._s(_vm.product.related_product1.name)+" ")])])]):_vm._e()],1),_c('router-link',{staticClass:"black--text not-text-decoration",attrs:{"to":_vm.route2}},[(
              _vm.product.related_product2 && _vm.product.related_product2.url !== ''
            )?_c('v-col',{staticClass:"related-product-column item-related",attrs:{"cols":"12","md":"auto"}},[_c('div',{staticClass:"background-avatar"},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.product.related_product2.intro_miniature !== null)?_c('v-img',{staticClass:"background-avatar--img",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
                      _vm.product.related_product2.intro_miniature.path,"eager":""}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"name-related-product"},[_vm._v(" "+_vm._s(_vm.product.related_product2.name)+" ")])])]):_vm._e()],1),_c('router-link',{staticClass:"black--text not-text-decoration",attrs:{"to":_vm.route3}},[(
              _vm.product.related_product3 && _vm.product.related_product3.url !== ''
            )?_c('v-col',{staticClass:"related-product-column item-related",attrs:{"cols":"12","md":"auto"}},[_c('div',{staticClass:"background-avatar"},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.product.related_product3.intro_miniature !== null)?_c('v-img',{staticClass:"background-avatar--img",attrs:{"src":'https://www.lakewaycms.actstudio.xyz' +
                      _vm.product.related_product3.intro_miniature.path,"eager":""}}):_vm._e()],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('h3',{staticClass:"name-related-product"},[_vm._v(" "+_vm._s(_vm.product.related_product3.name)+" ")])])]):_vm._e()],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }